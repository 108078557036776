import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Мийка та сервіс Mastery Insight Hub
			</title>
			<meta name={"description"} content={"Ми розуміємо, наскільки важливо підтримувати автомобіль у відмінному стані. Mastery Insight Hub – це не просто автосервіс і автомийка. Це місце, де ваш автомобіль отримає професійний догляд, а ви – спокій та впевненість у безпеці на дорозі."} />
			<meta property={"og:title"} content={"Мийка та сервіс Mastery Insight Hub"} />
			<meta property={"og:description"} content={"Ми розуміємо, наскільки важливо підтримувати автомобіль у відмінному стані. Mastery Insight Hub – це не просто автосервіс і автомийка. Це місце, де ваш автомобіль отримає професійний догляд, а ви – спокій та впевненість у безпеці на дорозі."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header12 />
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 16px 0px" font="--headline1" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					<Strong>
						Наші послуги
					</Strong>
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="--lead">
					Спектр наших послуг
					<br />
					Для уточнень та запитань звертайтеся за вказаним номером.
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="20px 30px 20px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline4">
							<Strong>
								Технічне обслуговування (ТО)
							</Strong>
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="left" font="--base">
							Заміна масла та фільтрів.
							<br />
							<br />
							Перевірка гальмівної системи.
							<br />
							<br />
							Огляд підвіски.
							<br />
							<br />
							Ціна: від 800 грн.
						</Text>
						<Link
							href="/kontakti"
							text-decoration-line="initial"
							color="--light"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
							margin="1rem 0px 0px 0px"
						>
							Дізнатися більше
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="20px 30px 20px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline4">
							Діагностика автомобіля
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="left" font="--base">
							Комп’ютерна діагностика.
							<br />
							<br />
							Перевірка двигуна, трансмісії, електрики.
							<br />
							<br />
							Ціна: від 500 грн.
						</Text>
						<Link
							href="/kontakti"
							text-decoration-line="initial"
							color="--light"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
							margin="1rem 0px 0px 0px"
						>
							Дізнатися більше
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="20px 30px 20px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline4">
							Ремонтні роботи
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="left" font="--base">
							Двигун: від 3000 грн.
							<br />
							<br />
							Підвіска: від 1500 грн.
							<br />
							<br />
							Електрика: від 700 грн.
						</Text>
						<Link
							href="/kontakti"
							text-decoration-line="initial"
							color="--light"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
							margin="1rem 0px 0px 0px"
						>
							Дізнатися більше
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="20px 30px 20px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline4">
							Автомийка
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="left" font="--base">
							Експрес-мийка кузова: 150 грн.
							<br />
							<br />
							Комплексна мийка (з хімчисткою салону): 500 грн.
							<br />
							<br />
							Нанесення воскового покриття: 200 грн.
						</Text>
						<Link
							href="/kontakti"
							text-decoration-line="initial"
							color="--light"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
							margin="1rem 0px 0px 0px"
						>
							Дізнатися більше
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="20px 30px 20px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline4">
							Хімчистка салону
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="left" font="--base">
							Очищення тканинних сидінь: 800 грн.
							<br />
							<br />
							Шкіряний салон: 1200 грн.
							<br />
							<br />
						</Text>
						<Link
							href="/kontakti"
							text-decoration-line="initial"
							color="--light"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
							margin="1rem 0px 0px 0px"
						>
							Дізнатися більше
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Box
						display="flex"
						flex-direction="column"
						padding="20px 30px 20px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline4">
							Додаткові послуги
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="left" font="--base">
							Очищення тканинних сидінь: 800 грн.
							<br />
							<br />
							Шкіряний салон: 1200 грн.
							<br />
							<br />
						</Text>
						<Link
							href="/kontakti"
							text-decoration-line="initial"
							color="--light"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
							margin="1rem 0px 0px 0px"
						>
							Дізнатися більше
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Bene />
		<Components.Faq1324 />
		<Components.Footer123 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});